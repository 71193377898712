import React from "react";
import Steps from './Steps'
import Pricing from "./Pricing";

export default function HowItWorks() {
  return (
    <>
      <Steps />
      <Pricing />
    </>
  );
}
